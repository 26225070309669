<template>
	<div>
		<div class="d-flex justify-end">
			<v-btn color="primary" text elevation="0" @click="generateReport">
			<v-icon>mdi-pdf-box</v-icon>生成PDF文件
			</v-btn>
		</div>
		<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="true"
				:preview-modal="true"
				:paginate-elements-by-height="1400"
				filename="项目工作组用时"
				:pdf-quality="2"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="1100px"
				ref="html2Pdf"
			>
				<section slot="pdf-content">
					<br />
					<h1 style="width: 100%;">{{projectName}}项目工作组用时</h1>
					<br />
					<div class="body-2">Print Date: {{todayDate}}</div>
					<br />
					<br />
					<br />
					<!-- PDF Content Here -->
					<vue-c3 :handler="handler"></vue-c3>
					<v-divider class="mt-6"></v-divider>
					<v-divider class="mt-2"></v-divider>
						<table class="pdf-datatable">
							<thead>
								<tr>
									<td class="text-left">
										工作团队
									</td>
									<td class="text-left">
										包工所用时间(小时)
									</td>
									<td class="text-left">
										天工所用时间(小时)
									</td>
									<td class="text-left">
										团队总共用时(小时)
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
								v-for="item in teams"
								:key="item.id"
								>
								<td><strong>{{ item.name }}</strong></td>
								<td>{{ item.package.total_hours }}</td>
								<td>{{ item.day_labour.total_hours }}</td>
								<td>{{ item.package.total_hours +  item.day_labour.total_hours }}</td>
								</tr>
							</tbody>
						</table>
				</section>
			</vue-html2pdf>

		<vue-c3 :handler="handler"></vue-c3>
		<v-divider class="mt-6"></v-divider>
		<v-divider class="mt-2"></v-divider>
			<v-simple-table>
				<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							工作团队
						</th>
						<th class="text-left">
							包工所用时间(小时)
						</th>
						<th class="text-left">
							天工所用时间(小时)
						</th>
						<th class="text-left">
							团队总共用时(小时)
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
					v-for="item in teams"
					:key="item.id"
					>
					<td><strong>{{ item.name }}</strong></td>
					<td>{{ item.package.total_hours }}</td>
					<td>{{ item.day_labour.total_hours }}</td>
					<td>{{ item.package.total_hours +  item.day_labour.total_hours }}</td>
					</tr>
				</tbody>
				</template>
			</v-simple-table>
	</div>
</template>
<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import ReportServices from '../../services/Report';
import VueHtml2pdf from 'vue-html2pdf';
import {  endOfDay, format } from 'date-fns';

export default {
	name: 'ReportProjectWorkTeamSummary',
	data: () => ({
		handler: new Vue(),
		teams: []
	}),
	props: {
		projectId: {
			type: Number,
			required: true
		},
		projectName: {
			type: String,
			required: false
		}
	},
	components: {
		VueC3,
		VueHtml2pdf
	},
	watch: {
	},
	computed: {
		todayDate(){
			return format(endOfDay(new Date()), 'dd/MM/yyyy');
		}
	},
	created(){
		if(this.projectId){
			this.getProjectWorkTeamSummaryReport();
		}
	},
	methods: {
		async getProjectWorkTeamSummaryReport(){
				try {
				let params = {
					"project_id": this.projectId,
				};
				let response = await ReportServices.projectWorkTeamSummaryReport(params);
				if(response.data.statusCode === 200){
					this.teams = response.data.data.work_teams;
					let x = ['x'];
					let packageLabourHrs = ['包工所用时间(小时)'];
					let daylabourHrs = ['天工所用时间(小时)'];
					response.data.data.work_teams.forEach(item => {
						x.push(item.name);
						daylabourHrs.push(item.day_labour.total_hours);
						packageLabourHrs.push(item.package.total_hours);
					});

					const options = {
						data: {
							x : 'x',
							columns: [
								x,
								daylabourHrs,
								packageLabourHrs
							],
	
							type: 'bar',
							colors: {
								'包工所用时间(小时)': '#81C784',
        					    '天工所用时间(小时)': '#80D8FF',
							}
						},
							axis: {
								x: {
									type: 'category' // this needed to load string x value
								}
							}
					}
					this.handler.$emit('init', options); 
				}
			} catch(error){
				console.log(error)
			}
		},
		generateReport () {
			this.$refs.html2Pdf.generatePdf()
		},
	}
}
</script>

