<template>
	<div>
		<div class="d-flex justify-end">
			<v-btn color="primary" text elevation="0" @click="generateReport">
				<v-icon>mdi-pdf-box</v-icon>生成PDF文件</v-btn>
		</div>
	<vue-html2pdf
		:show-layout="false"
		:float-layout="true"
		:enable-download="true"
		:preview-modal="true"
		:paginate-elements-by-height="1400"
		filename="天工工种时间报表"
		:pdf-quality="2"
		:manual-pagination="false"
		pdf-format="a4"
		pdf-orientation="landscape"
		pdf-content-width="1100px"
		ref="html2Pdf"
	>
		<section slot="pdf-content">

			<br />
			<h1 style="width: 100%;">{{projectName}}天工工种时间报表</h1>
			<br />
			<div class="body-2">Print Date: {{todayDate}}</div>
			<br />
			<br />
			<br />
			<!-- PDF Content Here -->
			<vue-c3 :handler="handler"></vue-c3>
			<v-divider class="mt-6"></v-divider>
			<v-divider class="mt-2"></v-divider>
				<table class="pdf-datatable">
					<thead>
						<tr>
						<td class="text-left">
							工种
						</td>
						<td class="text-left">
							天工已用时间 (单位: 小时)
						</td>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in worktypes"
						:key="item.id"
						>
						<td class="text-left"><strong>{{ item.name }}</strong></td>
						<td class="text-left">{{ item.total_hours}}</td>
						</tr>
					</tbody>
				</table>
		</section>
	</vue-html2pdf>

	<vue-c3 :handler="handler"></vue-c3>
	<v-divider class="mt-6"></v-divider>
	<v-divider class="mt-2"></v-divider>
		<v-simple-table>
			<template v-slot:default>
			<thead>
				<tr>
				<th class="text-left">
					工种
				</th>
				<th class="text-left">
					天工已用时间 (单位: 小时)
				</th>
				</tr>
			</thead>
			<tbody>
				<tr
				v-for="item in worktypes"
				:key="item.id"
				>
				<td><strong>{{ item.name }}</strong></td>
				<td>{{ item.total_hours}}</td>
				</tr>
			</tbody>
			</template>
		</v-simple-table>
	</div>
</template>
<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import ReportServices from '../../services/Report';
import VueHtml2pdf from 'vue-html2pdf';
import {  endOfDay, format } from 'date-fns';
export default {
	name: 'ReportDayLabourBudget',
	data: () => ({
		handler: new Vue(),
		worktypes: []
	}),
	props: {
		projectId: {
			type: Number,
			required: true
		},
		projectName: {
			type: String,
			required: false
		}
	},
	components: {
		VueC3,
		VueHtml2pdf
	},
	watch: {
	},
	computed: {
		todayDate(){
			return format(endOfDay(new Date()), 'dd/MM/yyyy');
		}
	},
	created(){
		if(this.projectId){
			this.getWorkTypeReport();
		}
	},
	methods: {
		async getWorkTypeReport(){
			try {
				let params = {
					"project_id": this.projectId,
				};
				let response = await ReportServices.projectWorkTypeReport(params);
				if(response.data.statusCode === 200){
					this.worktypes = response.data.data.work_types;
					let worktypes = [];
					response.data.data.work_types.forEach((type)=>{
						let column = [];
						column.push(type.name);
						column.push(type.total_hours);
						worktypes.push(column);
					});
					const options = {
						data: {
						columns: worktypes,
						type: 'pie'
						},
					}
					this.handler.$emit('init', options);
				}
			} catch(error){
				console.log(error)
			}
		},
		generateReport () {
			this.$refs.html2Pdf.generatePdf()
		},
	}
}
</script>

<style>
.pdf-datatable{
	border: 1px solid #ddd;
	width: 100%;
	border-collapse: collapse;
}
.pdf-datatable th,
.pdf-datatable td{
	border: 1px solid #ccc;
	padding:6px 12px;
}
</style>