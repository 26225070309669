<template>
	<div>
		<v-select
			:items="project_teams"
			v-model="selected_team"
			label="选择项目工作组"
			item-text="name"
			item-value="id"
			dense
			outlined
			@change="getProjectWorkTeamBreakdownSummary($event)"
		></v-select>
		<template v-if="selected_team">
			<div class="d-flex justify-end mb-2">
				<v-btn color="primary" text elevation="0" @click="generateReport">
				<v-icon>mdi-pdf-box</v-icon>生成PDF文件
				</v-btn>
			</div>
			<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="true"
				:preview-modal="true"
				:paginate-elements-by-height="1400"
				filename="项目工作组详情"
				:pdf-quality="2"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="1100px"
				ref="html2Pdf"
			>
				<section slot="pdf-content">
					<br />
					<h1 style="width: 100%;">{{projectName}}项目工作组详情</h1>
					<br />
					<div class="body-2">Print Date: {{todayDate}}</div>
					<br />
					<br />
					<br />
					<v-card flat tile outlined>
						<template v-for="item in breakdown">
							<div :key="item.id">
									<table width="100%"  style="boder-bottom:1px solid #bbb; background-color:#eee;">
										<tr>
											<td>
											<strong>{{ item.date | formatDate}}</strong></td>
											<td width="20%">
												<span>天工人数:<strong>{{item.day_labour.worker_count}}</strong></span>
												&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
												<span>包工人数:<strong>{{item.package.worker_count}}</strong></span>
											</td>
										</tr>
									</table>
									<table width="100%" style="boder-top:1px solid #bbb;">
										<tr>
											<td width="50%" style="border-right: 1px solid #bbb;">
												<table width="100%"  class="grey lighten-5">
													<tr>
														<td>
															<strong>天工用时详情</strong></td>
														<td>
															<strong>总用时: {{item.day_labour.total_hours}}小时</strong>
														</td>
													</tr>
												</table>
													<v-divider></v-divider>
												<table width="100%">
													<thead>
														<tr>
														<td class="text-left">
															工种名称
														</td>
														<td class="text-right">
															用时(小时)
														</td>
														</tr>
													</thead>
													<tbody>
														<tr v-for="type in item.day_labour.work_types" :key="type.id">
															<td>{{ type.name }}</td>
															<td class="text-right">{{ type.total_hours }}</td>
														</tr>
													</tbody>
												</table>
											</td>
											<td width="50%">
												<table width="100%"  class="grey lighten-5">
														<tr>
															<td>
																<strong>包工用时详情</strong></td>
															<td>
																<strong>总用时: {{item.package.total_hours}}小时</strong>
															</td>
														</tr>
													</table>
													<v-divider></v-divider>
													<table width="100%">
															<thead>
																<tr>
																<td class="text-left">
																	工种名称
																</td>
																<td class="text-right">
																	用时(小时)
																</td>
																</tr>
															</thead>
															<tbody>
																<tr v-for="type in item.package.work_types" :key="type.id">
																	<td>{{ type.name }}</td>
																	<td class="text-right">{{ type.total_hours }}</td>
																</tr>
															</tbody>
													</table>
											</td>
										</tr>
									</table>
							</div>
						</template>
					</v-card>
				</section>
			</vue-html2pdf>
			<v-card flat tile outlined v-if="breakdown.length">
				<template v-for="item in breakdown">
					<v-expansion-panels
						flat tile dense 
						:key="item.id">
						<v-expansion-panel>
							<v-expansion-panel-header  class="grey lighten-5">
								<v-row no-gutters>
									<v-col cols="8">
										<strong>{{ item.date | formatDate}}</strong>
									</v-col>
									<v-col cols="4">
										<span>天工人数:<strong>{{item.day_labour.worker_count}}</strong></span>
										<v-divider vertical class="mx-2"></v-divider>
										<span>包工人数:<strong>{{item.package.worker_count}}</strong></span>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-divider></v-divider>
							<v-expansion-panel-content>
								<div class="d-flex justify-space-between">
									<div class="flex-grow-1">
										<div class="subtitle-2 pa-3 d-flex justify-space-between">
											<strong>天工用时详情</strong>
											<div><strong>总用时: {{item.day_labour.total_hours}}小时</strong></div>
										</div>
										<v-divider></v-divider>
										<v-simple-table dense>
											<template v-slot:default>
											<thead>
												<tr>
												<th class="text-left">
													工种名称
												</th>
												<th class="text-right">
													用时(小时)
												</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="type in item.day_labour.work_types" :key="type.id">
													<td>{{ type.name }}</td>
													<td class="text-right">{{ type.total_hours }}</td>
												</tr>
											</tbody>
											</template>
										</v-simple-table>
									</div>
									<v-divider vertical class="mx-1"></v-divider>
									<div class="flex-grow-1">
										<div class="subtitle-2 pa-3 d-flex justify-space-between">
											<strong>包工用时详情</strong>
											<div><strong>总用时: {{item.package.total_hours}}小时</strong></div>
										</div>
										<v-divider></v-divider>
										<v-simple-table dense>
											<template v-slot:default>
												<thead>
													<tr>
													<th class="text-left">
														工种名称
													</th>
													<th class="text-right">
														用时(小时)
													</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="type in item.package.work_types" :key="type.id">
														<td>{{ type.name }}</td>
														<td class="text-right">{{ type.total_hours }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</template>
			</v-card>
		</template>
	</div>
</template>
<script>
import ReportServices from '../../services/Report';
import ProjectServices from '../../services/Project';
import VueHtml2pdf from 'vue-html2pdf'
import {  parse, format, endOfDay } from 'date-fns';

export default {
	name: 'ReportWorkTeamBreakdownSummary',
	data: () => ({
		project_teams: [],
		selected_team: null,
		breakdown: []
	}),
	props: {
		projectId: {
			type: Number,
			required: true
		},
		projectName: {
			type: String,
			required: false
		}
	},
	components: {
		VueHtml2pdf
	},
	watch: {
	},
	filters: {
		formatDate(value){
			let parseDate = parse(value, 'yyyy-MM', new Date());
			return format(parseDate, 'yyyy年MM月');
		}
	},
	computed: {
		todayDate(){
			return format(endOfDay(new Date()), 'dd/MM/yyyy');
		}
	},
	created(){
		if(this.projectId){
			this.getProjectWorkTeams();
		}
	},
	methods: {
		async getProjectWorkTeams(){
			try {
				let response = await ProjectServices.listProjectWorkTeams(this.projectId);
				if(response.data.statusCode === 200){

					response.data.data.forEach(team=>{
						this.project_teams.push({
							id: team.id,
							name: team.name
						})
					});
				}
			} catch(error){
				console.log(error)
			}
		},
		async getProjectWorkTeamBreakdownSummary(team_id){
			try {
				let params = {
					"project_id": this.projectId,
					"work_team_id": team_id
				};
				let response = await ReportServices.projectWorkTeamBreakdownReport(params);
				if(response.data.statusCode === 200){
					this.breakdown = response.data.data.breakdown;
				}
			} catch(error){
				console.log(error)
			}
		},
		generateReport () {
			this.$refs.html2Pdf.generatePdf()
		},
	}
}
</script>

