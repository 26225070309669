import Api from '@/services/Api';

export default {
	getProjectList(page, itemsPerPage, status){
		return Api().get(`/project?page=${page}&per_page=${itemsPerPage}&status=${status}`);
	},
	getAssignedProjectList(page, itemsPerPage, status){
		return Api().get(`/project/assigned?page=${page}&per_page=${itemsPerPage}&status=${status}`);
	},
	createProject(body){
		return Api().post(`/project`, JSON.stringify(body));
	},
	getProjectInfo(projectID) {
		return Api().get(`/project/${projectID}`);
	},
	removeProjectSiteManager(projectId, managerId){
		return Api().delete(`/project/${projectId}/site_manager?user_id=${managerId}`);
	},
	addProjectSiteManager(projectId, managerId){
		return Api().post(`/project/${projectId}/site_manager?user_id=${managerId}`);
	},
	updateProjectInfo(projectId, body){
		return Api().put(`/project/${projectId}`, JSON.stringify(body));
	},
	listProjectWorkTeams(projectId){
		return Api().get(`/project/${projectId}/workteam`);
	},
	listProjectWorkTeamWorkers(workerId){
		return Api().get(`/project/workteam/${workerId}`)
	},
	listAvailableWorkTypes(projectId) {
		return Api().get(`/project/${projectId}/work_types`)
	}
}